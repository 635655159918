import { useRouter } from "next/router";

import { Link } from "@releaseit/ui";

import { Seo } from "@/components";
import { AuthForm } from "@/features/auth";

function LoginPage() {
  const router = useRouter();
  const callbackUrl = router.query.callbackUrl as string;

  return (
    <>
      <Seo templateTitle="Login" />

      {/* Hide the default layout */}
      <style>{`
        #header,
        #header-promo-bar,
        #footer {
          display: none;
        }

        #header + main {
          display: grid;
        }
      `}</style>

      <div className="flex h-full flex-col items-center justify-between space-y-16 bg-white pb-8 md:bg-gray-600 md:pt-[15vh]">
        <AuthForm callbackUrl={callbackUrl} />
        <Legal />
      </div>
    </>
  );
}

export default LoginPage;

/** Internal components */

function Legal() {
  return (
    <div className="flex items-baseline space-x-4 text-sm">
      <p className="font-medium text-gray-800 md:text-white">©2021 Releaseit</p>
      <p className="flex space-x-2 text-gray-500 md:text-white">
        <Link href="/page/terms-of-use" target="_blank" variant="unstyled">
          Terms of Use
        </Link>
        <span>|</span>
        <Link href="/page/privacy-policy" target="_blank" variant="unstyled">
          Privacy
        </Link>
      </p>
    </div>
  );
}
