import { useAutoAnimate } from "@formkit/auto-animate/react";
import NextLink from "next/link";
import { useRouter } from "next/router";

import { IconButton } from "@releaseit/ui";

import ChevronLeftIcon from "@releaseit/assets/icons/chevron-left.svg";
import ReleaseitLogo from "@releaseit/assets/images/releaseit-logo-green.svg";

import { type AuthFormStep, useAuthFormReducer } from "../../hooks/use-auth-form-reducer";
import { type CompleteStepProps, CompleteStep } from "./CompleteStep";
import { type IdentifyStepProps, IdentifyStep } from "./IdentifyStep";
import { type LoginStepProps, LoginStep } from "./LoginStep";
import { type SignUpStepProps, SignUpStep } from "./SignUpStep";
import { type VerifyStepProps, VerifyStep } from "./VerifyStep";

type AuthFormProps = {
  callbackUrl?: string;
};

export function AuthForm({ callbackUrl }: AuthFormProps) {
  const router = useRouter();
  const [state, dispatch] = useAuthFormReducer();

  const onIdentify: IdentifyStepProps["onIdentify"] = ({ email, exists }) => {
    dispatch({
      type: "TRANSITION",
      to: exists ? "LOGIN" : "SIGN_UP",
      email,
    });
  };

  const onLogin: LoginStepProps["onLogin"] = async () => {
    await router.push(callbackUrl ?? "/");
  };

  const onSignUp: SignUpStepProps["onSignUp"] = (values) => {
    const { contactNumber } = values;
    const { _id: contactNumberId, phoneNumber: mobileNumber } = contactNumber[0];

    dispatch({
      type: "TRANSITION",
      to: "VERIFY",
      contactNumberId,
      mobileNumber,
    });
  };

  const onVerify: VerifyStepProps["onVerify"] = () => {
    dispatch({
      type: "TRANSITION",
      to: "COMPLETE",
    });
  };

  const onComplete: CompleteStepProps["onComplete"] = async () => {
    await router.push(callbackUrl ?? "/");
  };

  return (
    <AuthFormWrapper
      header={<AuthFormHeader step={state.step} onReset={() => dispatch({ type: "RESET" })} />}
    >
      {state.step === "IDENTIFY" && <IdentifyStep onIdentify={onIdentify} />}
      {state.step === "LOGIN" && <LoginStep email={state.email} onLogin={onLogin} />}
      {state.step === "SIGN_UP" && <SignUpStep email={state.email} onSignUp={onSignUp} />}
      {state.step === "VERIFY" && (
        <VerifyStep
          mobileNumber={state.mobileNumber}
          contactNumberId={state.contactNumberId}
          onVerify={onVerify}
        />
      )}
      {state.step === "COMPLETE" && <CompleteStep onComplete={onComplete} />}
    </AuthFormWrapper>
  );
}

/** Internal components */

type AuthFormWrapperProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

function AuthFormWrapper({ header, children }: AuthFormWrapperProps) {
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  return (
    <div className="flex min-h-[540px] w-full flex-col md:max-w-md md:overflow-hidden md:rounded-lg">
      {header}
      <div ref={animationParent} className="grow bg-white p-8 md:px-12">
        {children}
      </div>
    </div>
  );
}

type AuthFormHeaderProps = {
  step: AuthFormStep;
  onReset: () => void;
};

function AuthFormHeader({ step, onReset }: AuthFormHeaderProps) {
  return (
    <div className="grid grid-cols-4 items-center gap-x-4 bg-gray-800 p-4">
      {(step === "LOGIN" || step === "SIGN_UP") && (
        <IconButton
          icon={<ChevronLeftIcon className="h-6 w-6" />}
          variant="text-light"
          size="xs"
          onClick={onReset}
        />
      )}

      <NextLink href="/">
        <a className="col-span-2 col-start-2 w-36 place-self-center">
          <ReleaseitLogo />
        </a>
      </NextLink>
    </div>
  );
}
