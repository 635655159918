import { useMutation } from "react-query";

import { Alert, Button, PasswordInput, TextInput } from "@releaseit/ui";

import { InferFormValues, useForm } from "@/hooks/use-form";
import { z } from "@/lib/zod";
import { InputError } from "@/types/errors";

import { registerWithPassword } from "../../api/register";
import { AuthTermsAndConditions } from "./AuthTermsAndConditions";

const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  mobileNumber: z.string(),
  password: z
    .string()
    .min(8, "Must be at least 8 characters.")
    .max(32, "Must be at most 32 characters.")
    .regex(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{0,}$/,
      "Must contain one uppercase, one lowercase and one number."
    ),
});

type Schema = InferFormValues<typeof schema>;

export type SignUpStepProps = {
  email: string;
  onSignUp: (values: Awaited<ReturnType<typeof registerWithPassword>>) => Promise<void> | void;
};

export function SignUpStep({ email, onSignUp }: SignUpStepProps) {
  const { register, handleSubmit, formState, ...form } = useForm({ schema });

  async function onSubmit(values: Schema) {
    const search = new URLSearchParams(window.location.search);
    const referrerId = search.get("referrerId") as string | undefined;

    registerMutation.mutate({ email, ...values, referrerId });
  }

  const registerMutation = useMutation({
    mutationFn: registerWithPassword,
    onSuccess: onSignUp,
    onError(error: Error) {
      if (error instanceof InputError) {
        error.assignToForm(form);
      } else {
        form.setError("FORM_ERROR", { message: error.message });
      }
    },
  });

  return (
    <form className="flex flex-col gap-y-6" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-center text-xl font-medium">Confirm your details</h1>

      {formState.errors.FORM_ERROR && (
        <Alert tone="negative">{formState.errors.FORM_ERROR.message}</Alert>
      )}

      <fieldset className="flex flex-col space-y-4" disabled={registerMutation.isLoading}>
        <input
          name="email"
          type="email"
          autoComplete="username"
          defaultValue={email}
          readOnly
          hidden
        />

        <div className="flex space-x-4">
          <TextInput
            {...register("firstName")}
            label="First name"
            autoComplete="given-name"
            isRequired
            error={formState.errors.firstName?.message}
          />
          <TextInput
            {...register("lastName")}
            label="Last name"
            autoComplete="family-name"
            isRequired
            error={formState.errors.lastName?.message}
          />
        </div>

        <TextInput
          {...register("mobileNumber")}
          label="Mobile phone"
          type="tel"
          autoComplete="tel"
          isRequired
          description="We will verify this number, have your phone with you."
          error={formState.errors.mobileNumber?.message}
        />

        <PasswordInput
          {...register("password")}
          label="Password"
          autoComplete="new-password"
          isRequired
          minLength={8}
          maxLength={32}
          error={formState.errors.password?.message}
        />

        <Button type="submit" isLoading={registerMutation.isLoading}>
          Continue
        </Button>
      </fieldset>

      <AuthTermsAndConditions />
    </form>
  );
}
